<template>
    <div class="page-container">
        <h1>Sign up now</h1>
        <div class="grid">
            <div>
                <button @click="openCheckout">Sign up now</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.page-container {
    max-width: 900px;
    margin: 2em auto;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
}

.grid {
    display: block;
}

.grid>* {
    padding: 1rem;
}

@media (min-width: 768px) {
    .grid {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
</style>


<script>
export default {
    mounted() {
        // 在组件加载时初始化Paddle SDK
        this.initializePaddle();
    },
    methods: {
        initializePaddle() {
            // eslint-disable-next-line no-undef
            Paddle.Environment.set("sandbox");

            // 初始化Paddle SDK
            // eslint-disable-next-line no-undef
            Paddle.Initialize({
                token: "test_84ce33adf178126eb9563935a65", // 替换为客户端令牌
                eventCallback: function (data) {
                    console.log(data);
                    if (data.name == "checkout.completed") {
                        console.log("completed");
                    }
                }
            });
        },
        openCheckout() {
            // 定义项目列表和客户信息
            let itemsList = [
                {
                    priceId: "pri_01hsq7nc89aed8c9985sk9cyxh",
                    quantity: 1
                },
                // {
                //     priceId: "pri_01hsq9s9pjhbdxk5c2pcytyq9n",
                //     quantity: 1
                // }
            ];

            //   let customerInfo = {
            //     email: "sam@example.com",
            //     address: {
            //       countryCode: "US",
            //       postalCode: "10021"
            //     }
            //   };

            // 打开结账页面
            // eslint-disable-next-line no-undef
            Paddle.Checkout.open({
                items: itemsList,
                // customer: customerInfo
            });
        }
    }
};
</script>