
// router.js

import { createRouter, createWebHistory } from 'vue-router';
import ImageWatermark from './components/ImageWatermark.vue';
import PaddleCheckout from './components/PaddleCheckout.vue';
import Service from './components/Service.vue';
import PricePage from './components/PricePage.vue'

const routes = [
  {
    path: '/',
    component: ImageWatermark
  },
  {
    path: '/paddle',
    component: PaddleCheckout
  },
  {
    path: '/service',
    component: Service
  },
  {
    path: '/price',
    component: PricePage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
