<template>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h1>IDWaterMark</h1>
          <br />
          <h1>Service</h1>
  
          <section class="mb-4">
            <h2>Refund Policy</h2>
            <ul class="list-group">
              <li class="list-group-item">
                All purchases are non-refundable. Please consider your decisions carefully before making a purchase.
              </li>
              <li class="list-group-item">
                In special circumstances, such as when the service is unavailable or there is a major error, we may consider refund requests at our discretion.
              </li>
              <li class="list-group-item">
                Refund requests must be made within 7 days of purchase. To request a refund, please contact our customer support team.
              </li>
            </ul>
          </section>
  
          <section class="mb-4">
            <h2>Terms of Service</h2>
            <ul class="list-group">
              <li class="list-group-item">We will make every effort to provide you with the best possible service.</li>
              <li class="list-group-item">You agree to comply with our terms of service and use our services for lawful purposes.</li>
              <li class="list-group-item">We reserve the right to change the terms of service at any time. If you violate the terms of service, we may suspend or terminate your service.</li>
            </ul>
          </section>
  
          <section class="mb-4">
            <h2>Privacy Policy</h2>
            <ul class="list-group">
              <li class="list-group-item">We will not collect any of your personal information, such as your name, address, or email address.</li>
              <li class="list-group-item">We may collect some anonymous data, such as your IP address and browser type.</li>
              <li class="list-group-item">We use this data to improve our services and provide you with a better experience.</li>
              <li class="list-group-item">We will not share your personal information with third parties.</li>
            </ul>
          </section>
  
          <a href="/">Return</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ServiceComponent',
  }
  </script>
  